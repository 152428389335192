<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="figureone" v-if='you_xiaoping'>
        <!-- <div @click="dianji_quanping" style="border:0.01rem solid red;width:3.11rem;">1111</div> -->
        <!-- {{abcd}} -->
        <div class="xiaoping">
            <p class="xuanze_nianfen">
                年份:
                <el-select v-model="nianfen" placeholder="请选择" @change="nianfen_zhi">
                    <el-option
                        v-for="item in time_list"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                    <el-option value="测算" style="display: flex;align-items: center;">
                        <div style="font-size:0.1rem;color:#FFFFFF;text-align:center;background:#F16977;width:1.06rem;height:0.2rem;font-weight:lighter;line-height:0.2rem;">测算</div>
                    </el-option>
                </el-select>
            </p>
            <img v-if="nianfen==''" style="height:90%;width:100%;" src="../../assets/shili_tu1.png" alt="">
            <div class="tu_text" v-show="nianfen.length!=0">
                <p>
                    <span>(金额，占销售比)</span>
                    <span>单位:{{danwei}}</span>
                </p>
                <p>
                    <span>(目标销售额，完成率)</span>
                </p>
            </div>
            <div v-if="nianfen!=''" id="myCharts" :style="{width:'88%',height:'90%',background:'#fff'}"></div>
            <ul class="shili"  v-show="nianfen.length!=0">
                <li>
                    <span style="background:#6ABFA7;"></span>盈利区
                </li>
                <li>
                    <span style="background:#F16977;"></span>亏损区
                </li>
                <li>
                    <span style="background:#A9B0BD;"></span>变动经费
                </li>
                <li>
                    <span style="background:#E0E3E7;"></span>固定经费
                </li>
            </ul>
        </div>
        <div class="daping" v-if="quan_zia">
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">
                    盈亏平衡测算表(一)
                </p>
                <div class="box_nei">
                    <p class="xuanze_nianfen" v-show='cesuan_laile'>
                        年份:
                        <el-select v-model="nianfen" placeholder="请选择" @change="nianfen_zhi">
                            <el-option
                                v-for="item in time_list"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                            <el-option value="测算" style="display: flex;align-items: center;">
                                <div style="font-size:0.1rem;color:#FFFFFF;text-align:center;background:#F16977;width:1.06rem;height:0.2rem;font-weight:lighter;line-height:0.2rem;">测算</div>
                            </el-option>
                        </el-select>
                    </p>
                    <ul class="cesuan_biao" v-show="!cesuan_laile">
                        <li class="left_ce"><p><span class="span">*</span>本年目标销售额</p><input type="number" placeholder="请输入金额" v-model="chuan_bennian_jine"></li>
                        <li class="left_ce"><p><span class="span">*</span>固定费用</p><input type="number" placeholder="请输入金额" v-model="chuan_guding_jine"></li>
                        <li class="left_ce"><p><span class="span">*</span>变动经费率</p><input type="number" placeholder="请输入(1-100)数字" v-model="chuan_biandong_baifenbi" @keyup="keyu"><span class="span2">%</span></li>
                        <li><div style="cursor: pointer;" @click="dianji_cesuan">测算</div></li>
                    </ul>
                    <div class="tu_text" v-show="danwei_shili">
                        <p>
                            <span>(金额，占销售比)</span>
                            <span>单位:{{danwei}}</span>
                        </p>
                        <p>
                            <span>(目标销售额，完成率)</span>
                        </p>
                    </div>
                    <div class="tu_text_ce" v-show="cesuan.danwei_shili">
                        <p>
                            <span>(金额，占销售比)</span>
                            <span>单位:{{cesuan.danwei}}</span>
                        </p>
                        <p>
                            <span>(目标销售额，完成率)</span>
                        </p>
                    </div>
                    <div v-show="cesuan_laile" id="myCharts2" :style="{width:'88%',height:'65%',background:'#fff'}"></div>
                    <ul  class="shili"  v-show="danwei_shili">
                        <li>
                            <span style="background:#6ABFA7;"></span>盈利区
                        </li>
                        <li>
                            <span style="background:#F16977;"></span>亏损区
                        </li>
                        <li>
                            <span style="background:#A9B0BD;"></span>变动经费
                        </li>
                        <li>
                            <span style="background:#E0E3E7;"></span>固定经费
                        </li>
                    </ul>
                    <!-- 测算图 -->
                    <div v-show="cesuan.cesuan_laile2" id="myCharts3" :style="{width:'80%',height:'70%',background:'#fff'}"></div>
                    <ul  class="shili"  v-show="cesuan.danwei_shili">
                        <li>
                            <span style="background:#6ABFA7;"></span>盈利区
                        </li>
                        <li>
                            <span style="background:#F16977;"></span>亏损区
                        </li>
                        <li>
                            <span style="background:#A9B0BD;"></span>变动经费
                        </li>
                        <li>
                            <span style="background:#E0E3E7;"></span>固定经费
                        </li>
                    </ul>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { query_as_year_list, generate_as_breakeven_picture, compute_as_breakeven } from '../../api/api'
export default {
  name: 'figureone',
  props: {
    //   tuchu_chuanshu:String,
    quan_zi: String,
    required: true
  },
  data () {
    return {
      nianfen: '',
      time_list: [],
      dedao_zong_jine: '',
      pingheng_jine: '',
      guding_jine: '',
      jingying_lirun: '',
      biandong_jine: '',
      dedao_zong_jine_list: '', // 一百份
      dedao_zong_jine_list2: '', // 一百份
      x_zhou: [],
      fengzhi: '', // 最上边的线
      cizhi: '', // 第二条线,
      danwei: '元',
      cizhi_baifenbi: '',
      guding_baifenbi: '',
      pingheng_list: [],
      pingheng_baifenbi: '',
      jingying_lirun_zhi: '',
      jingying_lirun_zhi2: '',
      jingying_lirun_zhi3: '',
      jingying_lirun_zhi_baifenbi: '',
      jingying_lirun_zhi_baifenbi2: '100.00%',
      biandong_jingfei_zhi: '',
      biandong_jingfei_zhi2: '',
      biandong_jingfei_zhi_baifenbi: '',
      biandong_jingfei_zhi_baifenbi2: '',
      shuzhi: 100, // x轴数值
      hongse_list: [],
      shenhui: [],
      qianhui: [],
      qianhui2: [],
      danwei_shili: false,
      quan_zia: '',
      cesuan_laile: true,
      chuan_bennian_jine: '',
      chuan_guding_jine: '',
      chuan_biandong_baifenbi: '',
      pingheng_text: [],
      cesuan: {
        dedao_zong_jine: '',
        pingheng_jine: '',
        guding_jine: '',
        jingying_lirun: '',
        biandong_jine: '',
        dedao_zong_jine_list: '', // 一百份
        dedao_zong_jine_list2: '', // 一百份
        x_zhou: [],
        fengzhi: '', // 最上边的线
        cizhi: '', // 第二条线,
        danwei: '元',
        cizhi_baifenbi: '',
        guding_baifenbi: '',
        pingheng_list: [],
        pingheng_baifenbi: '',
        jingying_lirun_zhi_2: '',
        jingying_lirun_zhi2: '',
        jingying_lirun_zhi3: '',
        jingying_lirun_zhi_baifenbi: '',
        jingying_lirun_zhi_baifenbi2: '100.00%',
        biandong_jingfei_zhi: '',
        biandong_jingfei_zhi2: '',
        biandong_jingfei_zhi_baifenbi: '',
        biandong_jingfei_zhi_baifenbi2: '',
        shuzhi: 100, // x轴数值
        hongse_list: [],
        shenhui: [],
        qianhui: [],
        qianhui2: [],
        danwei_shili: false,
        cesuan_laile: false,
        pingheng_text: []

      },
      biaoge_nei: false,
      you_xiaoping: true
    }
  },
  computed: {
    jingying_lirun_zhi_2 () {
      return this.cesuan.jingying_lirun_zhi_2
    }
  },
  watch: {
    quan_zi (val) {
      console.log(val)
      this.quan_zia = val
      if (val == 1) {
        // this.dianji_quanping()
        this.nianfen_zhi2()
      } else if (val != '' && val != 1) {
        console.log(val)
        this.nianfen = val
        this.quan_zia = '1'
        this.nianfen_zhi()
        this.nianfen_zhi2()
      } else {

      }
    },
    // tuchu_chuanshu(val){
    //     console.log(val)
    //     this.nianfen=val
    //     this.quan_zia='1'
    //     this.nianfen_zhi()
    //     this.nianfen_zhi2()
    // },
    jingying_lirun_zhi () {
      if (this.quan_zia == 1) {
        this.tupian2()
      } else {
        this.tupian()
      }
    },
    jingying_lirun_zhi_2 () {
      this.tupian_ce()
    }
  },
  mounted () {
    console.log(this.abcd)
    this.jichu()
  },
  methods: {
    // dianji_quanping(){
    //     var root=document.getElementsByClassName("figureone")[0];
    //     root.style.cssText="height:100%;width:100%;position: fixed;top: 0;right: 0;bottom: 0;left: 0;overflow: auto;margin: 0;z-index:999;background: rgba(0,0,0,0.8);";
    //     this.tupian()
    // },
    // 基础文字
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          const list = []
          if (date[0] != null) {
            for (let i = 0; i < date.length; i++) {
              list.push(date[i] + '年')
            }
          }
          this.time_list = list
          date.forEach(it => {
            if (it == new Date().getFullYear()) {
              this.nianfen = new Date().getFullYear() + '年'
              this.nianfen_zhi()
            }
          })
          console.log(new Date().getFullYear())
        } else if (res.data.code == 10238) {}
      })
    },

    // 年份选择
    nianfen_zhi () {
      if (this.nianfen != '测算') {
        console.log(this.nianfen.slice(0, 4))
        generate_as_breakeven_picture({
          data: {
            ent_id: this.$ent_id(),
            year: this.nianfen.slice(0, 4)
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10239) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.dedao_zong_jine = date.amt_sale_aim_est
            this.pingheng_jine = date.breakeven_point
            this.guding_jine = date.fixed_funds
            this.jingying_lirun = date.profit
            this.biandong_jine = date.variable_funds
            if (this.you_xiaoping) {
              this.jisuan()
              this.tupian()
            }
            this.danwei_shili = true
            this.$emit('nianfen_fu', this.nianfen)
          } else if (res.data.code == 10240) {}
        })
      } else {
        this.cesuan_laile = false
        this.danwei_shili = false
        this.cesuan.cesuan_laile2 = true
        this.quan_zia = true
        this.nianfen = ''
      }
    },
    // 年份选择
    nianfen_zhi2 () {
      setTimeout(() => {
        this.tupian2()
      }, 0)
    },
    // 计算
    jisuan () {
      const xie_zhixian1 = []
      const xie_zhixian2 = []
      let pingheng = ''
      const index = ''
      const lv_list = []
      let guding = ''
      this.x_zhou = []
      this.hongse_list = []
      this.shenhui = []
      this.qianhui = []
      this.qianhui2 = []
      this.pingheng_list = []
      this.pingheng_text = []
      // 切换单位
      if (this.dedao_zong_jine > 0 && this.dedao_zong_jine < 10000) {
        this.danwei = '元'
      } else if (this.dedao_zong_jine >= 10000 && this.dedao_zong_jine < 100000000) {
        this.danwei = '万元'
        this.dedao_zong_jine = this.dedao_zong_jine / 10000
        this.guding_jine = this.guding_jine / 10000
        this.biandong_jine = this.biandong_jine / 10000
        this.pingheng_jine = this.pingheng_jine / 10000
        this.jingying_lirun = this.jingying_lirun / 10000
      } else if (this.dedao_zong_jine > 100000000) {
        this.danwei = '亿'
        this.dedao_zong_jine = this.dedao_zong_jine / 100000000
        this.guding_jine = this.guding_jine / 100000000
        this.biandong_jine = this.biandong_jine / 100000000
        this.pingheng_jine = this.pingheng_jine / 100000000
        this.jingying_lirun = this.jingying_lirun / 100000000
      }
      if (this.dedao_zong_jine >= this.pingheng_jine) {
        pingheng = (100 - Number(((this.pingheng_jine / this.dedao_zong_jine) * 100).toFixed(2))).toFixed(2)
        for (let i = 1; i <= 100; i++) {
          xie_zhixian1.push((this.dedao_zong_jine / 100) * i)
          xie_zhixian2.push(((this.biandong_jine) / 100) * i)
          this.x_zhou.push(i)
        }
        for (let i = 1; i < 100 - Number(pingheng); i++) {
          this.pingheng_list.push('')
          this.hongse_list.push(xie_zhixian2[0] * i)
          this.shenhui.push((xie_zhixian1[0] * i).toFixed(2))
          this.pingheng_text.push('')
        }
        this.pingheng_list.push(0)
        this.pingheng_text.push('')
        this.pingheng_text.push(this.pingheng_jine)
        console.log(4444, this.pingheng_text)
        for (let i = 0; i < (Number(pingheng)); i++) {
          this.pingheng_list.push(this.pingheng_jine)
        }
        this.fengzhi = Number(this.dedao_zong_jine).toFixed(2)
        this.cizhi = (Number(this.biandong_jine) + Number(this.guding_jine)).toFixed(2)
        this.jingying_lirun_zhi = Number(this.dedao_zong_jine).toFixed(2)
        this.jingying_lirun_zhi2 = Number(this.jingying_lirun).toFixed(2)
        this.jingying_lirun_zhi3 = Number(this.dedao_zong_jine).toFixed(2)
        this.biandong_jingfei_zhi = (Number(this.guding_jine) + Number(this.biandong_jine)).toFixed(2)
        this.biandong_jingfei_zhi2 = Number(this.biandong_jine).toFixed(2)
        this.cizhi_baifenbi = ((this.biandong_jine + this.guding_jine) / this.dedao_zong_jine * 100).toFixed(2) + '%'
        this.guding_baifenbi = (this.guding_jine / this.dedao_zong_jine * 100).toFixed(2) + '%'
        this.pingheng_baifenbi = (this.pingheng_jine / this.dedao_zong_jine * 100).toFixed(2) + '%'
        this.jingying_lirun_zhi_baifenbi = (this.jingying_lirun_zhi2 / this.dedao_zong_jine * 100).toFixed(2) + '%'
        this.biandong_jingfei_zhi_baifenbi = (Number(this.biandong_jine) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
        this.biandong_jingfei_zhi_baifenbi2 = ((Number(this.biandong_jine) + Number(this.guding_jine)) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
      } else {
        this.fengzhi = this.biandong_jine + this.guding_jine
        this.cizhi = this.dedao_zong_jine
        this.cizhi_baifenbi = (this.dedao_zong_jine / (this.biandong_jine + this.guding_jine) * 100).toFixed(2) + '%'
        this.guding_baifenbi = (this.guding_jine / (this.biandong_jine + this.guding_jine) * 100).toFixed(2) + '%'
        this.pingheng_baifenbi = (this.pingheng_jine / (this.biandong_jine + this.guding_jine) * 100).toFixed(2) + '%'
      }
      guding = (100 - Number(((this.guding_jine / this.dedao_zong_jine) * 100).toFixed(2))).toFixed(2)
      for (let i = 1; i < 100 - Number(guding); i++) {
        this.qianhui.push((xie_zhixian1[0] * i).toFixed(2))
        this.qianhui2.push('')
      }
      xie_zhixian1.unshift(0)
      xie_zhixian2.unshift(0)
      this.x_zhou.unshift(0)
      this.shenhui.unshift(0)
      this.qianhui.unshift(0)
      console.log(this.hongse_list)
      for (let i = 0; i < xie_zhixian2.length; i++) {
        xie_zhixian2[i] = Number(xie_zhixian2[i]) + this.guding_jine
      }
      this.hongse_list.unshift(0)
      for (let i = 0; i < this.hongse_list.length; i++) {
        this.hongse_list[i] = (Number(this.hongse_list[i]) + this.guding_jine).toFixed(2)
      }
      this.hongse_list.push(this.pingheng_jine)
      this.shenhui.push(this.pingheng_jine)
      this.qianhui.push(this.guding_jine)
      for (let i = 0; i < Number(guding) + 2; i++) {
        this.qianhui2.push(this.guding_jine)
      }
      this.dedao_zong_jine_list = xie_zhixian1
      this.dedao_zong_jine_list2 = xie_zhixian2
    },
    jisuan_ce () {
      const xie_zhixian1 = []
      const xie_zhixian2 = []
      let pingheng = ''
      const index = ''
      const lv_list = []
      let guding = ''
      this.cesuan.x_zhou = []
      this.cesuan.hongse_list = []
      this.cesuan.shenhui = []
      this.cesuan.qianhui = []
      this.cesuan.qianhui2 = []
      this.cesuan.pingheng_list = []
      this.cesuan.pingheng_text = []
      // 切换单位
      if (this.cesuan.dedao_zong_jine > 0 && this.cesuan.dedao_zong_jine < 10000) {
        this.cesuan.danwei = '元'
      } else if (this.cesuan.dedao_zong_jine >= 10000 && this.cesuan.dedao_zong_jine < 100000000) {
        this.cesuan.danwei = '万元'
        this.cesuan.dedao_zong_jine = this.cesuan.dedao_zong_jine / 10000
        this.cesuan.guding_jine = this.cesuan.guding_jine / 10000
        this.cesuan.biandong_jine = this.cesuan.biandong_jine / 10000
        this.cesuan.pingheng_jine = this.cesuan.pingheng_jine / 10000
        this.cesuan.jingying_lirun = this.cesuan.jingying_lirun / 10000
      } else if (this.cesuan.dedao_zong_jine > 100000000) {
        this.cesuan.danwei = '亿'
        this.cesuan.dedao_zong_jine = this.cesuan.dedao_zong_jine / 100000000
        this.cesuan.guding_jine = this.cesuan.guding_jine / 100000000
        this.cesuan.biandong_jine = this.cesuan.biandong_jine / 100000000
        this.cesuan.pingheng_jine = this.cesuan.pingheng_jine / 100000000
        this.cesuan.jingying_lirun = this.cesuan.jingying_lirun / 100000000
      }
      if (this.cesuan.dedao_zong_jine >= this.cesuan.pingheng_jine) {
        pingheng = (100 - Number(((this.cesuan.pingheng_jine / this.cesuan.dedao_zong_jine) * 100).toFixed(2))).toFixed(2)
        for (let i = 1; i <= 100; i++) {
          xie_zhixian1.push((this.cesuan.dedao_zong_jine / 100) * i)
          xie_zhixian2.push(((this.cesuan.biandong_jine) / 100) * i)
          this.cesuan.x_zhou.push(i)
        }
        for (let i = 1; i < 100 - Number(pingheng); i++) {
          this.cesuan.pingheng_list.push('')
          this.cesuan.hongse_list.push(xie_zhixian2[0] * i)
          this.cesuan.shenhui.push((xie_zhixian1[0] * i).toFixed(2))
          this.cesuan.pingheng_text.push('')
        }
        this.cesuan.pingheng_list.push(0)
        this.cesuan.pingheng_text.push('')
        this.cesuan.pingheng_text.push(this.cesuan.pingheng_jine)
        console.log(this.cesuan.pingheng_text)
        for (let i = 0; i < 1 + (Number(pingheng)); i++) {
          this.cesuan.pingheng_list.push(this.cesuan.pingheng_jine)
        }
        this.cesuan.fengzhi = Number(this.cesuan.dedao_zong_jine).toFixed(2)
        this.cesuan.cizhi = (Number(this.cesuan.biandong_jine) + Number(this.cesuan.guding_jine)).toFixed(2)
        this.cesuan.jingying_lirun_zhi_2 = Number(this.cesuan.dedao_zong_jine).toFixed(2)
        this.cesuan.jingying_lirun_zhi2 = Number(this.cesuan.jingying_lirun).toFixed(2)
        this.cesuan.jingying_lirun_zhi3 = Number(this.cesuan.dedao_zong_jine).toFixed(2)
        this.cesuan.biandong_jingfei_zhi = (Number(this.cesuan.guding_jine) + Number(this.cesuan.biandong_jine)).toFixed(2)
        this.cesuan.biandong_jingfei_zhi2 = Number(this.cesuan.biandong_jine).toFixed(2)
        this.cesuan.cizhi_baifenbi = ((this.cesuan.biandong_jine + this.cesuan.guding_jine) / this.cesuan.dedao_zong_jine * 100).toFixed(2) + '%'
        this.cesuan.guding_baifenbi = (this.cesuan.guding_jine / this.cesuan.dedao_zong_jine * 100).toFixed(2) + '%'
        this.cesuan.pingheng_baifenbi = (this.cesuan.pingheng_jine / this.cesuan.dedao_zong_jine * 100).toFixed(2) + '%'
        this.cesuan.jingying_lirun_zhi_baifenbi = (this.cesuan.jingying_lirun_zhi2 / this.cesuan.dedao_zong_jine * 100).toFixed(2) + '%'
        this.cesuan.biandong_jingfei_zhi_baifenbi = (Number(this.cesuan.biandong_jine) / Number(this.cesuan.dedao_zong_jine) * 100).toFixed(2) + '%'
        this.cesuan.biandong_jingfei_zhi_baifenbi2 = ((Number(this.cesuan.biandong_jine) + Number(this.cesuan.guding_jine)) / Number(this.cesuan.dedao_zong_jine) * 100).toFixed(2) + '%'
      } else {
        this.cesuan.fengzhi = this.cesuan.biandong_jine + this.cesuan.guding_jine
        this.cesuan.cizhi = this.cesuan.dedao_zong_jine
        this.cesuan.cizhi_baifenbi = (this.cesuan.dedao_zong_jine / (this.cesuan.biandong_jine + this.cesuan.guding_jine) * 100).toFixed(2) + '%'
        this.cesuan.guding_baifenbi = (this.cesuan.guding_jine / (this.cesuan.biandong_jine + this.cesuan.guding_jine) * 100).toFixed(2) + '%'
        this.cesuan.pingheng_baifenbi = (this.cesuan.pingheng_jine / (this.cesuan.biandong_jine + this.cesuan.guding_jine) * 100).toFixed(2) + '%'
      }
      guding = (100 - Number(((this.cesuan.guding_jine / this.cesuan.dedao_zong_jine) * 100).toFixed(2))).toFixed(2)
      for (let i = 1; i < 100 - Number(guding); i++) {
        this.cesuan.qianhui.push((xie_zhixian1[0] * i).toFixed(2))
        this.cesuan.qianhui2.push('')
      }
      xie_zhixian1.unshift(0)
      xie_zhixian2.unshift(0)
      this.cesuan.x_zhou.unshift(0)
      this.cesuan.shenhui.unshift(0)
      this.cesuan.qianhui.unshift(0)
      console.log(this.cesuan.hongse_list)
      for (let i = 0; i < xie_zhixian2.length; i++) {
        xie_zhixian2[i] = Number(xie_zhixian2[i]) + this.cesuan.guding_jine
      }
      this.cesuan.hongse_list.unshift(0)
      for (let i = 0; i < this.cesuan.hongse_list.length; i++) {
        this.cesuan.hongse_list[i] = (Number(this.cesuan.hongse_list[i]) + this.cesuan.guding_jine).toFixed(2)
      }
      this.cesuan.hongse_list.push(this.cesuan.pingheng_jine)
      this.cesuan.shenhui.push(this.cesuan.pingheng_jine)
      this.cesuan.qianhui.push(this.cesuan.guding_jine)
      for (let i = 0; i < Number(guding) + 2; i++) {
        this.cesuan.qianhui2.push(this.cesuan.guding_jine)
      }
      this.cesuan.dedao_zong_jine_list = xie_zhixian1
      this.cesuan.dedao_zong_jine_list2 = xie_zhixian2
    },
    tupian () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts'))

      myChart.setOption({
        grid: {
          y: 60,
          x: 160,
          x2: 130,
          y2: 35
        },
        xAxis: [
          {
            data: this.x_zhou,
            axisTick: {
              show: false
            },
            // axisLabel:{
            //     show:false,
            // },
            splitLine: {
              show: false
            },
            boundaryGap: false,
            axisPointer: {
              show: true,
              type: 'line',
              snap: true,
              lineStyle: {
                color: '#6AD4FF',
                width: 1,
                type: 'solid'
              },
              triggerTooltip: true,
              value: this.shuzhi,
              handle: {
                show: true,
                size: 0
              },
              label: {
                show: true,
                backgroundColor: '#fff',
                shadowColor: '#fff',
                color: '#4C4A4D',
                formatter: params => {
                  if (params.seriesData.length != 0) {
                    this.shuzhi = params.value
                    const jingying = params.seriesData[0].data
                    const biandong = params.seriesData[1].data
                    if (jingying <= this.pingheng_jine) {
                      this.jingying_lirun_zhi = Number(jingying).toFixed(2)
                      this.jingying_lirun_zhi2 = -(Number(biandong) - Number(jingying)).toFixed(2)
                      this.jingying_lirun_zhi_baifenbi = (-((Number(biandong) - Number(jingying)) / Number(this.dedao_zong_jine) * 100)).toFixed(2) + '%'
                      this.jingying_lirun_zhi_baifenbi2 = (Number(jingying) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    } else {
                      this.jingying_lirun_zhi = Number(jingying).toFixed(2)
                      this.jingying_lirun_zhi2 = (Number(jingying) - Number(biandong)).toFixed(2)
                      this.jingying_lirun_zhi_baifenbi = (-((Number(biandong) - Number(jingying)) / Number(this.dedao_zong_jine) * 100)).toFixed(2) + '%'
                      this.jingying_lirun_zhi_baifenbi2 = (Number(jingying) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    }
                    this.biandong_jingfei_zhi = Number(biandong).toFixed(2)
                    this.biandong_jingfei_zhi2 = (Number(biandong) - Number(this.guding_jine)).toFixed(2)
                    this.biandong_jingfei_zhi_baifenbi = (Number(this.biandong_jingfei_zhi2) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    this.biandong_jingfei_zhi_baifenbi2 = (Number(biandong) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    this.jingying_lirun_zhi3 = Number(jingying).toFixed(2)
                  }
                  return '完成率' + params.value + '%'
                }
              }
            }
          },
          {
            data: this.x_zhou,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            boundaryGap: false
          },
          {
            data: this.x_zhou,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            position: 'top',
            boundaryGap: false
            //    offset:'30',
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        // axisPointer :{
        //     link: {xAxisIndex: 'all'}
        // },
        tooltip: {
          formatter: params => {
            console.log(params)
            let zhi = ''
            let name = ''
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName == 'zong') {
                console.log(params[i].value)
                zhi = Number(params[i].value).toFixed(2)
                name = '预计完成(' + zhi + ',' + Number(params[i].name).toFixed(2) + '%)'
              }
            }
            return name
          }
        },
        series: [
          {
            name: 'zong',
            data: this.dedao_zong_jine_list,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            areaStyle: {
              color: '#6ABFA7',
              opacity: 1
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 0
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '(' + this.fengzhi + ',' + '100.00%)',
                  yAxis: this.fengzhi
                }
              ]
            }
          },
          {
            data: this.dedao_zong_jine_list2,
            type: 'line',
            color: '#000000',
            showSymbol: false,
            areaStyle: {
              color: '#A9B0BD',
              opacity: 1
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 0
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '(' + this.cizhi + ',' + this.cizhi_baifenbi + ')',
                  yAxis: this.cizhi
                },
                {
                  name: '(' + Number(this.pingheng_jine).toFixed(2) + ',' + this.pingheng_baifenbi + ')',
                  yAxis: this.pingheng_jine
                }
              ]
            }
          },
          {
            type: 'line',
            data: this.pingheng_list,
            showSymbol: false,
            lineStyle: {
              width: 1,
              color: '#000000',
              type: 'dotted'
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 1,
                type: 'solid',
                color: '#000000'
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '固定经费(' + this.guding_jine.toFixed(2) + ',' + this.guding_baifenbi + ')',
                  yAxis: this.guding_jine,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.guding_jine.toFixed(2) + ',' + this.guding_baifenbi + ')',
                  yAxis: this.guding_jine
                },
                {
                  name: '经营利润(' + this.jingying_lirun_zhi2 + ',' + this.jingying_lirun_zhi_baifenbi + ')',
                  yAxis: this.jingying_lirun_zhi,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.jingying_lirun_zhi3 + ',' + this.jingying_lirun_zhi_baifenbi2 + ')',
                  yAxis: this.jingying_lirun_zhi
                }
              ]
            }
          },
          {
            type: 'line',
            showSymbol: false,
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 1,
                type: 'dotted',
                color: '#000000'
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '变动经费(' + this.biandong_jingfei_zhi2 + ',' + this.biandong_jingfei_zhi_baifenbi + ')',
                  yAxis: this.biandong_jingfei_zhi,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.biandong_jingfei_zhi + ',' + this.biandong_jingfei_zhi_baifenbi2 + ')',
                  yAxis: this.biandong_jingfei_zhi
                }
              ]
            }
          },
          {
            data: this.hongse_list,
            type: 'line',
            color: '#000',
            showSymbol: false,
            areaStyle: {
              color: '#F16977',
              opacity: 1
            }
          },
          {
            data: this.shenhui,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            areaStyle: {
              color: '#A9B0BD',
              opacity: 1
            }
          },
          {
            data: this.qianhui,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            areaStyle: {
              color: '#E0E3E7',
              opacity: 1
            }
          },
          {
            data: this.qianhui2,
            type: 'line',
            color: '#000',
            showSymbol: false,
            areaStyle: {
              color: '#E0E3E7',
              opacity: 1
            },
            lineStyle: {
              width: 1,
              color: '#000000'
            }
          },
          {
            data: this.pingheng_text,
            type: 'line',
            color: '#000',
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '盈亏平衡点'
              }
            }
          }
        ]

      })
    },
    tupian2 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts2'))
      myChart.setOption({
        grid: {
          y: 20,
          x: 160,
          x2: 200,
          y2: 35
        },
        xAxis: [
          {
            data: this.x_zhou,
            axisTick: {
              show: false
            },
            // axisLabel:{
            //     show:false,
            // },
            splitLine: {
              show: false
            },
            boundaryGap: false,
            axisPointer: {
              show: true,
              type: 'line',
              snap: true,
              lineStyle: {
                color: '#6AD4FF',
                width: 1,
                type: 'solid'
              },
              triggerTooltip: true,
              value: this.shuzhi,
              handle: {
                show: true,
                size: 0
              },
              label: {
                show: true,
                backgroundColor: '#fff',
                shadowColor: '#fff',
                color: '#4C4A4D',
                formatter: params => {
                  if (params.seriesData.length != 0) {
                    this.shuzhi = params.value
                    const jingying = params.seriesData[0].data
                    const biandong = params.seriesData[1].data
                    if (jingying <= this.pingheng_jine) {
                      this.jingying_lirun_zhi = Number(jingying).toFixed(2)
                      this.jingying_lirun_zhi2 = -(Number(biandong) - Number(jingying)).toFixed(2)
                      this.jingying_lirun_zhi_baifenbi = (-((Number(biandong) - Number(jingying)) / Number(this.dedao_zong_jine) * 100)).toFixed(2) + '%'
                      this.jingying_lirun_zhi_baifenbi2 = (Number(jingying) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    } else {
                      this.jingying_lirun_zhi = Number(jingying).toFixed(2)
                      this.jingying_lirun_zhi2 = (Number(jingying) - Number(biandong)).toFixed(2)
                      this.jingying_lirun_zhi_baifenbi = (-((Number(biandong) - Number(jingying)) / Number(this.dedao_zong_jine) * 100)).toFixed(2) + '%'
                      this.jingying_lirun_zhi_baifenbi2 = (Number(jingying) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    }
                    this.biandong_jingfei_zhi = Number(biandong).toFixed(2)
                    this.biandong_jingfei_zhi2 = (Number(biandong) - Number(this.guding_jine)).toFixed(2)
                    this.biandong_jingfei_zhi_baifenbi = (Number(this.biandong_jingfei_zhi2) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    this.biandong_jingfei_zhi_baifenbi2 = (Number(biandong) / Number(this.dedao_zong_jine) * 100).toFixed(2) + '%'
                    this.jingying_lirun_zhi3 = Number(jingying).toFixed(2)
                  }
                  return '完成率' + params.value + '%'
                }
              }
            }
          },
          {
            data: this.x_zhou,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            boundaryGap: false
          },
          {
            data: this.x_zhou,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            position: 'top',
            boundaryGap: false
            //    offset:'30',
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        tooltip: {
          formatter: params => {
            console.log(params)
            let zhi = ''
            let name = ''
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName == 'zong') {
                zhi = Number(params[i].value).toFixed(2)
                name = '预计完成(' + zhi + ',' + Number(params[i].name).toFixed(2) + '%)'
              }
            }
            return name
          }
        },
        // axisPointer :{
        //     link: {xAxisIndex: 'all'}
        // },
        series: [
          {
            name: 'zong',
            data: this.dedao_zong_jine_list,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            areaStyle: {
              color: '#6ABFA7',
              opacity: 1
            },
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 0
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '(' + this.fengzhi + ',' + '100.00%)',
                  yAxis: this.fengzhi
                }
              ]
            }
          },
          {
            data: this.dedao_zong_jine_list2,
            type: 'line',
            color: '#000000',
            showSymbol: false,
            areaStyle: {
              color: '#A9B0BD',
              opacity: 1
            },
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 0
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '(' + this.cizhi + ',' + this.cizhi_baifenbi + ')',
                  yAxis: this.cizhi
                },
                {
                  name: '(' + this.pingheng_jine + ',' + this.pingheng_baifenbi + ')',
                  yAxis: this.pingheng_jine
                }
              ]
            }
          },
          {
            type: 'line',
            data: this.pingheng_list,
            showSymbol: false,
            lineStyle: {
              width: 1,
              color: '#000000',
              type: 'dotted'
            },
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 1,
                type: 'solid',
                color: '#000000'
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '固定经费(' + this.guding_jine.toFixed(2) + ',' + this.guding_baifenbi + ')',
                  yAxis: this.guding_jine,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.guding_jine.toFixed(2) + ',' + this.guding_baifenbi + ')',
                  yAxis: this.guding_jine
                },
                {
                  name: '经营利润(' + this.jingying_lirun_zhi2 + ',' + this.jingying_lirun_zhi_baifenbi + ')',
                  yAxis: this.jingying_lirun_zhi,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.jingying_lirun_zhi3 + ',' + this.jingying_lirun_zhi_baifenbi2 + ')',
                  yAxis: this.jingying_lirun_zhi
                }
              ]
            }
          },
          {
            type: 'line',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 1,
                type: 'dotted',
                color: '#000000'
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '变动经费(' + this.biandong_jingfei_zhi2 + ',' + this.biandong_jingfei_zhi_baifenbi + ')',
                  yAxis: this.biandong_jingfei_zhi,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.biandong_jingfei_zhi + ',' + this.biandong_jingfei_zhi_baifenbi2 + ')',
                  yAxis: this.biandong_jingfei_zhi
                }
              ]
            }
          },
          {
            data: this.hongse_list,
            type: 'line',
            color: '#000',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#F16977',
              opacity: 1
            }
          },
          {
            data: this.shenhui,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#A9B0BD',
              opacity: 1
            }
          },
          {
            data: this.qianhui,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#E0E3E7',
              opacity: 1
            }
          },
          {
            data: this.qianhui2,
            type: 'line',
            color: '#000',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#E0E3E7',
              opacity: 1
            },
            lineStyle: {
              width: 1,
              color: '#000000'
            }
          },
          {
            data: this.pingheng_text,
            type: 'line',
            color: '#000',
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '盈亏平衡点'
              }
            }
          }
        ]

      })
    },
    tupian_ce () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts3'))

      myChart.setOption({
        grid: {
          y: 20,
          x: 160,
          x2: 200,
          y2: 35
        },
        xAxis: [
          {
            data: this.cesuan.x_zhou,
            axisTick: {
              show: false
            },
            // axisLabel:{
            //     show:false,
            // },
            splitLine: {
              show: false
            },
            boundaryGap: false,
            axisPointer: {
              show: true,
              type: 'line',
              snap: true,
              lineStyle: {
                color: '#6AD4FF',
                width: 1,
                type: 'solid'
              },
              triggerTooltip: true,
              value: this.cesuan.shuzhi,
              handle: {
                show: true,
                size: 0
              },
              label: {
                show: true,
                backgroundColor: '#fff',
                shadowColor: '#fff',
                color: '#4C4A4D',
                formatter: params => {
                  if (params.seriesData.length != 0) {
                    this.cesuan.shuzhi = params.value
                    const jingying = params.seriesData[0].data
                    const biandong = params.seriesData[1].data
                    if (jingying <= this.cesuan.pingheng_jine) {
                      this.cesuan.jingying_lirun_zhi_2 = Number(jingying).toFixed(2)
                      this.cesuan.jingying_lirun_zhi2 = -(Number(biandong) - Number(jingying)).toFixed(2)
                      this.cesuan.jingying_lirun_zhi_baifenbi = (-((Number(biandong) - Number(jingying)) / Number(this.cesuan.dedao_zong_jine) * 100)).toFixed(2) + '%'
                      this.cesuan.jingying_lirun_zhi_baifenbi2 = (Number(jingying) / Number(this.cesuan.dedao_zong_jine) * 100).toFixed(2) + '%'
                    } else {
                      this.cesuan.jingying_lirun_zhi_2 = Number(jingying).toFixed(2)
                      this.cesuan.jingying_lirun_zhi2 = (Number(jingying) - Number(biandong)).toFixed(2)
                      this.cesuan.jingying_lirun_zhi_baifenbi = (-((Number(biandong) - Number(jingying)) / Number(this.cesuan.dedao_zong_jine) * 100)).toFixed(2) + '%'
                      this.cesuan.jingying_lirun_zhi_baifenbi2 = (Number(jingying) / Number(this.cesuan.dedao_zong_jine) * 100).toFixed(2) + '%'
                    }
                    this.cesuan.biandong_jingfei_zhi = Number(biandong).toFixed(2)
                    this.cesuan.biandong_jingfei_zhi2 = (Number(biandong) - Number(this.cesuan.guding_jine)).toFixed(2)
                    this.cesuan.biandong_jingfei_zhi_baifenbi = (Number(this.cesuan.biandong_jingfei_zhi2) / Number(this.cesuan.dedao_zong_jine) * 100).toFixed(2) + '%'
                    this.cesuan.biandong_jingfei_zhi_baifenbi2 = (Number(biandong) / Number(this.cesuan.dedao_zong_jine) * 100).toFixed(2) + '%'
                    this.cesuan.jingying_lirun_zhi3 = Number(jingying).toFixed(2)
                  }
                  return '完成率' + params.value + '%'
                }
              }
            }
          },
          {
            data: this.cesuan.x_zhou,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            boundaryGap: false
          },
          {
            data: this.cesuan.x_zhou,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            position: 'top',
            boundaryGap: false
            //    offset:'30',
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          },
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        tooltip: {
          formatter: params => {
            console.log(params)
            let zhi = ''
            let name = ''
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName == 'zong') {
                zhi = Number(params[i].value).toFixed(2)
                name = '预计完成(' + zhi + ',' + Number(params[i].name).toFixed(2) + '%)'
              }
            }
            return name
          }
        },
        // axisPointer :{
        //     link: {xAxisIndex: 'all'}
        // },
        series: [
          {
            name: 'zong',
            data: this.cesuan.dedao_zong_jine_list,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            areaStyle: {
              color: '#6ABFA7',
              opacity: 1
            },
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 0
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '(' + this.cesuan.fengzhi + ',' + '100.00%)',
                  yAxis: this.cesuan.fengzhi
                }
              ]
            }
          },
          {
            data: this.cesuan.dedao_zong_jine_list2,
            type: 'line',
            color: '#000000',
            showSymbol: false,
            areaStyle: {
              color: '#A9B0BD',
              opacity: 1
            },
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 0
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '(' + this.cesuan.cizhi + ',' + this.cesuan.cizhi_baifenbi + ')',
                  yAxis: this.cesuan.cizhi
                },
                {
                  name: '(' + this.cesuan.pingheng_jine + ',' + this.cesuan.pingheng_baifenbi + ')',
                  yAxis: this.cesuan.pingheng_jine
                }
              ]
            }
          },
          {
            type: 'line',
            data: this.cesuan.pingheng_list,
            showSymbol: false,
            lineStyle: {
              width: 1,
              color: '#000000',
              type: 'dotted'
            },
            itemStyle: {
              opacity: 0
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 1,
                type: 'solid',
                color: '#000000'
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '固定经费(' + this.cesuan.guding_jine.toFixed(2) + ',' + this.cesuan.guding_baifenbi + ')',
                  yAxis: this.cesuan.guding_jine,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.cesuan.guding_jine.toFixed(2) + ',' + this.cesuan.guding_baifenbi + ')',
                  yAxis: this.cesuan.guding_jine
                },
                {
                  name: '经营利润(' + this.cesuan.jingying_lirun_zhi2 + ',' + this.cesuan.jingying_lirun_zhi_baifenbi + ')',
                  yAxis: this.cesuan.jingying_lirun_zhi_2,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.cesuan.jingying_lirun_zhi3 + ',' + this.cesuan.jingying_lirun_zhi_baifenbi2 + ')',
                  yAxis: this.cesuan.jingying_lirun_zhi_2
                }
              ]
            }
          },
          {
            type: 'line',
            itemStyle: {
              opacity: 0
            },
            showSymbol: false,
            markLine: {
              symbol: 'none',
              lineStyle: {
                opacity: 1,
                type: 'dotted',
                color: '#000000'
              },
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      console.log(params)
                      const value = params.name
                      return value
                    },
                    color: '#4C4A4D'
                  }
                }
              },
              data: [
                {
                  name: '变动经费(' + this.cesuan.biandong_jingfei_zhi2 + ',' + this.cesuan.biandong_jingfei_zhi_baifenbi + ')',
                  yAxis: this.cesuan.biandong_jingfei_zhi,
                  label: {
                    position: 'start'
                  }
                },
                {
                  name: '(' + this.cesuan.biandong_jingfei_zhi + ',' + this.cesuan.biandong_jingfei_zhi_baifenbi2 + ')',
                  yAxis: this.cesuan.biandong_jingfei_zhi
                }
              ]
            }
          },
          {
            data: this.cesuan.hongse_list,
            type: 'line',
            color: '#000',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#F16977',
              opacity: 1
            }
          },
          {
            data: this.cesuan.shenhui,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#A9B0BD',
              opacity: 1
            }
          },
          {
            data: this.cesuan.qianhui,
            type: 'line',
            color: '#FFB466',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#E0E3E7',
              opacity: 1
            }
          },
          {
            data: this.cesuan.qianhui2,
            type: 'line',
            color: '#000',
            showSymbol: false,
            itemStyle: {
              opacity: 0
            },
            areaStyle: {
              color: '#E0E3E7',
              opacity: 1
            },
            lineStyle: {
              width: 1,
              color: '#000000'
            }
          },
          {
            data: this.cesuan.pingheng_text,
            type: 'line',
            color: '#000',
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '盈亏平衡点'
              }
            }
          }
        ]

      })
    },
    // 监听变动净费率是否填写
    keyu () {
      if (this.chuan_biandong_baifenbi == '') {
        this.chuan_biandong_baifenbi == ''
      } else if (this.chuan_biandong_baifenbi <= 0) {
        this.chuan_biandong_baifenbi = 1
      } else if (this.chuan_biandong_baifenbi > 100) {
        this.chuan_biandong_baifenbi = 100
      }
    },
    // 点击测算
    dianji_cesuan () {
      if (this.chuan_bennian_jine != '' && this.chuan_guding_jine != '' && this.chuan_biandong_baifenbi != '') {
        compute_as_breakeven({
          data: {
            fixed_funds: this.chuan_guding_jine + '',
            variable_funds_rate: this.chuan_biandong_baifenbi + '',
            amt_aim: this.chuan_bennian_jine + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.cesuan.dedao_zong_jine = date.amt_sale_aim_est
            this.cesuan.pingheng_jine = date.breakeven_point
            this.cesuan.guding_jine = date.fixed_funds
            this.cesuan.jingying_lirun = date.profit
            this.cesuan.biandong_jine = date.variable_funds
            this.jisuan_ce()
            setTimeout(() => {
              this.tupian_ce()
            }, 0)
            this.cesuan.danwei_shili = true
          } else if (res.data.code == 201) {}
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        })
      }
    },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      // this.quan_zia=''
      // this.$emit('nianfen_fu', '1')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
      if (this.biaoge_nei) {
        this.quan_zia = false
        this.you_xiaoping = false
      } else {
        this.quan_zia = false
      }
      this.cesuan.cesuan_laile2 = false
      this.cesuan_laile = true
      this.danwei_shili = false
    },
    biaoge_dakai () {
      this.you_xiaoping = false
    },
    dakai_quanping (data) {
      console.log(data)
      this.you_xiaoping = true
      this.nianfen = data.time
      this.quan_zia = data.chutu
      this.biaoge_nei = data.biaoge_nei
      if (data.chutu) {
        this.nianfen_zhi()
        this.nianfen_zhi2()
      } else {
        this.nianfen_zhi2()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figureone {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        position: relative;
        .xuanze_nianfen{
            font-size: 0.12rem;
            color:#4C4A4D;
            display: flex;
            align-items: center;
            padding-top:0.18rem;
            margin-left: 0.16rem;
        }
        .tu_text{
            position: absolute;
            top:0.5rem;
            left: 0.32rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:76.5%;
            z-index: 1;
            p{
                span{
                    &:nth-child(1){
                        font-size: 0.14rem;
                        color: #4C4A4D;
                    }
                    &:nth-child(2){
                        font-size: 0.1rem;
                        color: #4C4A4D;
                    }
                }
            }
        }
        .shili{
            position: absolute;
            right:0.47rem;
            top:0.48rem;
            li{
                display: flex;
                align-items: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                height:0.32rem;
                span{
                    display: block;
                    height:0.08rem;
                    width:0.08rem;
                    margin-right: 0.03rem;
                }
            }
        }
    }
    .daping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .box_nei{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                .xuanze_nianfen{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    display: flex;
                    align-items: center;
                    padding-top:0.18rem;
                    margin-left: 0.16rem;
                }
                .cesuan_biao{
                    // position: absolute;
                    padding-top:0.18rem;
                    margin-left: 0.16rem;
                    .left_ce{
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.19rem;
                        position: relative;
                        p{
                            width:1.1rem;
                            text-align: right;
                            font-size: 0.14rem;
                            color:#4C4A4D;
                            margin-right: 0.19rem;
                            .span{
                                font-size: 0.14rem;
                                color:#F16977;
                            }
                        }
                        .span2{
                            position: absolute;
                            left:2.55rem;
                            font-size: 0.14rem;
                        }
                        input{
                            height:0.3rem;
                            width:1.16rem;
                            padding:0 0.12rem;
                            border:0.01rem solid #888888;
                            border-radius: 0.04rem;
                        }
                    }
                    li{
                        div{
                            width:2.4rem;
                            height:0.35rem;
                            margin-left:0.17rem;
                            font-size: 0.14rem;
                            color:#fff;
                            text-align: center;
                            line-height: 0.35rem;
                            border-radius: 0.03rem;
                            background:#F16977;
                        }
                    }
                }
                .tu_text{
                    position: absolute;
                    top:20%;
                    left: 18.5%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width:55%;
                    p{
                        span{
                            &:nth-child(1){
                                font-size: 0.14rem;
                                color: #4C4A4D;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                                color: #4C4A4D;
                            }
                        }
                    }
                }
                .tu_text_ce{
                    position: absolute;
                    top:2rem;
                    left: 2.88rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width:55%;
                    p{
                        span{
                            &:nth-child(1){
                                font-size: 0.14rem;
                                color: #4C4A4D;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                                color: #4C4A4D;
                            }
                        }
                    }
                }
                #myCharts2{
                    position: absolute;
                    top:2rem;
                }
                .shili{
                    position: absolute;
                    right:1.87rem;
                    top:1.48rem;
                    li{
                        display: flex;
                        align-items: center;
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        height:0.67rem;
                        span{
                            display: block;
                            height:0.17rem;
                            width:0.17rem;
                            margin-right: 0.17rem;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .daping {
            width:1600px;
        }
    }
}
</style>
